import { i18n } from "@/main";
const t = i18n.global.t;
const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
export const email = (val) => emailPattern.test(val) || t("rules.email");
export const required = (val) => !!val || t("rules.required");
export const passwordLength = (val) => val.length == 0 || val.length >= 8 || t("rules.length8");
export const maxLength30 = (val) => !val || val.length <= 30 || t("rules.lengthTo30");
export const maxLength50 = (val) => !val || val.length <= 50 || t("rules.lengthTo50");
export const maxLength100 = (val) => !val || val.length <= 100 || t("rules.lengthTo100");
export const imei = (val) => !val || val.length === 16 || t("rules.imei");
